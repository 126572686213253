@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

:root {
	--primary: #08A300;
	--theme-color: #fff;
}

html {
	height: 100%;
}

body {
	/* font-family: Arial, Helvetica, sans-serif; */
	height: 100%;
	position: relative;
}

#root {
	height: 100%;
}

.main-content {
	height: 100%;
}

::-webkit-scrollbar {
	width: 0.3rem;
	height: 0.3rem;
}

::-webkit-scrollbar-thumb {
	background: #8b909e;
	border-radius: 10px;
}

::-webkit-scrollbar-track {
	background: #b8c0c8;
	border-radius: 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

th {
	vertical-align: middle;
	font-size: 0.85rem;
}

.authentication-bg {
	height: 100vh;
	background: linear-gradient(90deg, var(--primary), #00AF50);
}

.hover:hover {
	color: var(--primary);
	cursor: pointer;
}

.del {
	text-decoration: line-through;
}

.vertical-middle {
	vertical-align: middle;
}

.vertical-center {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.vertical-end {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

.h-fit-content {
	height: fit-content;
}

.tooltip[x-placement^="bottom"],
.show[x-placement^="bottom"]:not(.tooltip) {
	margin-top: 0;
}

.input-borer-only {
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 5px;
}

.btn-rounded {
	border-radius: 30px;
}

.btn-wide {
	padding: 0.42rem 2rem;
}

.btn-wide-2 {
	padding: 0.42rem 0.6rem;
}

.lang-item:hover {
	color: var(--primary);
}

.color-00 {
	color: #000;
}

.color-dark-theme {
	color: #212529;
}

.bg-border {
	padding: 2px 10px;
	border-radius: 15px;
}

.dshbutton {
	cursor: pointer;
	display: inline-flex;
	align-items: center;
}

.dshbutton .current {
	display: block;
	width: 0.75rem;
	height: 0.75rem;
	border-radius: 9999px;
	margin-right: 0.5rem;
	border-width: 3px;
	background-color: #00e396;
	pointer-events: none;
}

.dshbutton .last {
	display: block;
	width: 0.75rem;
	height: 0.75rem;
	border-radius: 9999px;
	margin-right: 0.5rem;
	border-width: 3px;
	background-color: #f1b44c;
	pointer-events: none;
}

.cursor {
	cursor: pointer;
}

.cursor-default {
	cursor: default;
}

.table-action-button {
	width: 25px;
	height: 25px;
	cursor: pointer;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	border-radius: 5px;
}

.table-action-button i {
	font-size: 18px;
}

.table-action-primary-button {
	color: var(--primary);
	border: 1px solid var(--primary);
}

.table-action-primary-button:hover {
	color: #fff;
	border-color: var(--primary);
	background-color: var(--primary);
}

.table-action-success-button {
	color: #34c38f;
	border: 1px solid #34c38f;
}

.table-action-success-button:hover {
	color: #fff;
	border-color: #34c38f;
	background-color: #34c38f;
}

.table-action-danger-button {
	color: #f46a6a;
	border: 1px solid #f46a6a;
}

.table-action-danger-button:hover {
	color: #fff;
	border-color: #f46a6a;
	background-color: #f46a6a;
}

.table-no-image {
	width: 50px;
	height: 50px;
	border-radius: 15px;
	text-align: center;
	background-color: #d9d9d9;
}

.inventory-modal-height {
	max-height: 400px;
	overflow: auto;
}

.modal-460 {
	height: 460px;
	overflow: auto;
}

.inventory-modal-height::-webkit-scrollbar {
	display: none;
}

.btn-wrapper {
	display: flex;
	justify-content: flex-end;
	flex-wrap: wrap;
	margin-top: 3rem;
}

.fz-12 {
	font-size: 12px !important;
}

.fz-14 {
	font-size: 14px !important;
}

.fz-16 {
	font-size: 16px !important;
}

.fz-18 {
	font-size: 18px;
}

.fz-20 {
	font-size: 20px;
}

.fz-22 {
	font-size: 22px;
}

.fz-24 {
	font-size: 24px;
}

.fz-26 {
	font-size: 26px;
}

.fz-28 {
	font-size: 28px;
}

.fz-30 {
	font-size: 30px;
}

.fz-32 {
	font-size: 32px;
}

.fz-34 {
	font-size: 34px;
}

.fw-500 {
	font-weight: 500;
}

.fw-600 {
	font-weight: 600;
}

.fw-700 {
	font-weight: 700;
}

.settings_title {
	font-weight: 700;
	letter-spacing: 4px;
	text-transform: uppercase;
	color: #5d5d5d;
	margin-bottom: 10px;
}

.rightbar-close-icon {
	right: 5px;
	top: -15px;
	font-size: 30px;
	position: absolute;
	cursor: pointer;
}

.logoColor {
	color: var(--primary);
	font-weight: 600;
}

.input-search-icon {
	position: absolute;
	top: 22%;
	right: 9px;
}

.page-header {
	height: 40px;
	margin-bottom: 10px;
}

.page-item:first-child .page-link {
	margin-left: 0;
	border-top-left-radius: 0.25rem;
	border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
	border-top-right-radius: 0.25rem;
	border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
	z-index: 3;
	color: #fff;
	background-color: var(--primary);
	border-color: var(--primary);
}

.page-item.disabled .page-link {
	color: #6c757d;
	pointer-events: none;
	cursor: auto;
	background-color: #fff;
	border-color: #dee2e6;
}

.pagination-lg .page-link {
	padding: 0.75rem 1.5rem;
	font-size: 1.25rem;
	line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
	border-top-left-radius: 0.3rem;
	border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
	border-top-right-radius: 0.3rem;
	border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
	padding: 0.25rem 0.5rem;
	font-size: 0.875rem;
	line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
	border-top-left-radius: 0.2rem;
	border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
	border-top-right-radius: 0.2rem;
	border-bottom-right-radius: 0.2rem;
}

.pagination .page-link-item {
	border-radius: 5px;
}

.pagination .prev-link-pagination {
	border-radius: 5px;
	margin-right: 5px;
	color: rgba(0, 0, 0, 0.6);
}

.pagination {
	display: inline-block;
	justify-content: end;
	padding-left: 0;
	margin: 1rem 0;
	padding: 7px;
	border-radius: 4px;
	font-size: 0.8rem;
}

.pagination>ul {
	background-color: #f0f0f0;
}

.pagination>li {
	display: inline;
	margin-right: 10px;
}

.pagination .page-link-item {
	border-radius: 5px;
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
	z-index: 3;
	color: #fff;
	cursor: default;
	background-color: var(--primary);
	border-color: var(--primary);
}

.pagination>.disabled>a {
	cursor: default;
	pointer-events: none;
	opacity: 0.5;
	background-color: #f0f0f0;
	border-color: transparent;
	border: 1px solid #f0f0f0;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span {
	margin-left: 0;
}

.pagination>li>a,
.pagination>li>span {
	position: relative;
	float: left;
	padding: 5px 12px;
	margin-left: -1px;
	line-height: 1.4;
	color: var(--primary);
	text-decoration: none;
	border: 1px solid #f0f0f0;
	border-radius: 5px;
}

.pagination>li>a:hover,
.pagination>li>span:hover {
	color: var(--primary);
}

.pagination .next,
.previous {
	display: none !important;
}

.auth-card {
	z-index: 999999;
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-clip: border-box;
	border: 0 solid #f6f6f6;
	border-radius: 0.25rem;
}

.auth-card ::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #fff;
	opacity: 1;
	/* Firefox */
}

.calendar-input {
	position: relative;
}

.calendar-input i {
	position: absolute;
	right: 8px;
	top: 8px;
	color: hsl(0, 0%, 80%);
}

.login-input {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	outline: 0;
	border: 1px solid rgba(255, 255, 255, 0.3);
	background-color: rgba(255, 255, 255, 0.1);
	width: 250px;
	border-radius: 3px;
	padding: 10px 15px;
	margin: 0 auto 10px auto;
	display: block;
	text-align: center;
	font-size: 18px;
	color: #fff;
	transition-duration: 0.25s;
	font-weight: 400;
}

.login-input:hover {
	background-color: fade(white, 40%);
}

.login-input::placeholder {
	color: #fff;
	opacity: 0.4;
}

.login-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	outline: 0;
	background-color: white;
	border: 0;
	padding: 10px 15px;
	color: var(--primary);
	border-radius: 3px;
	width: 250px;
	cursor: pointer;
	font-size: 18px;
	transition-duration: 0.25s;
}

.login-button:hover {
	background-color: #f5f7f9;
}

.lang-position {
	top: 15px;
	z-index: 99999;
	right: 15px;
	color: #fff;
}

.circles {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.circles li {
	position: absolute;
	display: block;
	list-style: none;
	width: 20px;
	height: 20px;
	background: rgba(0, 0, 0, 0.4);
	animation: animate 25s linear infinite;
	bottom: -150px;
}

.circles li:nth-child(1) {
	left: 25%;
	width: 80px;
	height: 80px;
	animation-delay: 0s;
}

.circles li:nth-child(2) {
	left: 10%;
	width: 20px;
	height: 20px;
	animation-delay: 2s;
	animation-duration: 12s;
}

.circles li:nth-child(3) {
	left: 70%;
	width: 20px;
	height: 20px;
	animation-delay: 4s;
}

.circles li:nth-child(4) {
	left: 40%;
	width: 60px;
	height: 60px;
	animation-delay: 0s;
	animation-duration: 18s;
}

.circles li:nth-child(5) {
	left: 65%;
	width: 20px;
	height: 20px;
	animation-delay: 0s;
}

.circles li:nth-child(6) {
	left: 75%;
	width: 110px;
	height: 110px;
	animation-delay: 3s;
}

.circles li:nth-child(7) {
	left: 35%;
	width: 150px;
	height: 150px;
	animation-delay: 7s;
}

.circles li:nth-child(8) {
	left: 50%;
	width: 25px;
	height: 25px;
	animation-delay: 15s;
	animation-duration: 45s;
}

.circles li:nth-child(9) {
	left: 20%;
	width: 15px;
	height: 15px;
	animation-delay: 2s;
	animation-duration: 35s;
}

.circles li:nth-child(10) {
	left: 85%;
	width: 150px;
	height: 150px;
	animation-delay: 0s;
	animation-duration: 11s;
}

@keyframes animate {
	0% {
		transform: translateY(0) rotate(0deg);
		opacity: 1;
		border-radius: 0;
	}

	100% {
		transform: translateY(-1000px) rotate(720deg);
		opacity: 0;
		border-radius: 50%;
	}
}

/* IMAGE UPLOAD */

.con-img-upload .img-upload .btn-x-file i {
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
	position: relative;
	padding: 4px;
	font-size: 1.4rem;
	display: -ms-flexbox;
	display: -webkit-box;
	display: flex;
	-ms-flex-align: center;
	-webkit-box-align: center;
	align-items: center;
	-ms-flex-pack: center;
	-webkit-box-pack: center;
	justify-content: center;
	border-radius: 5px;
	background: rgba(0, 0, 0, 0.1);
	color: #fff;
	text-shadow: 0 3px 10px rgba(0, 0, 0, 0.5);
}

.con-img-upload .img-upload .btn-x-file {
	right: 5px;
}

.con-img-upload .img-upload .btn-x-file {
	display: block;
	position: absolute;
	top: 5px;
	padding: 0;
	margin: 0;
	-webkit-transform: translate(20%, -20%);
	transform: translate(20%, -20%);
	background: transparent;
	z-index: 300;
	opacity: 0;
	border: 0;
	cursor: pointer;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
	border-radius: 10px;
}

.con-img-upload .img-upload:hover .btn-x-file {
	opacity: 1;
	-webkit-transform: translate(0);
	transform: translate(0);
}

.con-img-upload .img-upload .btn-x-file:hover i {
	border-radius: 50%;
	background: #924040;
}

.con-img-upload .img-upload .btn-x-file:hover~img {
	-webkit-filter: grayscale(80%);
	filter: grayscale(80%);
}

.con-input-upload:hover {
	border: 1px dashed var(--primary);
}

.con-upload {
	width: 100%;
	position: relative;
}

.con-img-upload {
	width: 100%;
	background: #fff;
	margin-top: 5px;
	padding-right: 5px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-column-gap: 5px;
	-moz-column-gap: 5px;
	column-gap: 5px;
}

.con-img-upload,
.con-img-upload .img-upload {
	position: relative;
	border-radius: 10px;
	overflow: hidden;
}

.con-img-upload {
	overflow: hidden;
	margin: 0;
	padding-right: 0;
}

.con-input-upload {
	background: #f5f5f5;
	width: 200px;
	height: 50px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	border-radius: 8px;
	position: relative;
	-webkit-transition: all 0.25s ease;
	transition: all 0.25s ease;
	border: 1px dashed rgba(0, 0, 0, 0.1);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	margin: 15px;
}

.con-img-upload .img-upload {
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	background: rgba(0, 80, 0, 0.5);
	background: #fff;
	-webkit-box-shadow: 0 5px 20px 0 rgb(0 0 0 / 10%);
	box-shadow: 0 5px 20px 0 rgb(0 0 0 / 10%);
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	width: 200px;
	height: 200px;
	margin: 5px;
	cursor: pointer;
	float: left;
}

.con-img-upload .img-upload {
	width: 100%;
}

.con-input-upload input {
	position: absolute;
	width: 100%;
	height: 100%;
	opacity: 0;
	left: 0;
	top: 0;
	cursor: pointer;
}

.con-input-upload .text-input {
	min-width: 200px;
	display: block;
	text-align: center;
	margin: 0 !important;
	padding: 0 !important;
	width: 100%;
	font-weight: 700;
}

.con-input-upload {
	float: left;
	margin: 0;
}

.table-control {
	position: relative;
}

.table-control-icon {
	position: absolute;
	right: -1px;
	top: -2px;
	cursor: pointer;
}

.table-control-icon:hover {
	color: var(--primary);
}

/* RIGHTBAAR CHEQUE */

.custom-cheque-table th {
	padding: 0;
}

.cheque-border-bottom {
	border-bottom: 1px solid #eee;
}

.border-bottom-primary {
	border-bottom: 1px solid var(--primary);
}

.border-bottom-danger {
	border-bottom: 1px solid #f46a6a;
}

.right-sidebar-background {
	position: absolute;
	top: 0px;
	left: 0;
	z-index: 52010;
	background: rgba(0, 0, 0, 0.2);
	width: 100%;
	height: 100vh;
	position: fixed;
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
	opacity: 1;
}

.right-sidebar {
	overflow-y: auto;
	z-index: 52010;
	width: 450px;
	max-width: 90vw;
	right: 0;
	left: auto;
	height: calc(var(--vh, 1vh) * 100);
	background: #fff;
	position: fixed;
	top: 0;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
	transition: all 0.25s ease;
}

.cheque-no-auth {
	overflow-y: auto;
	min-height: 100vh;
	width: 450px;
	background: #fff;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
	transition: all 0.25s ease;
}

/* LOADER */

#loading-bg {
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 20000;
	display: block;
	position: fixed;
}

.loading-logo {
	position: absolute;
	left: calc(50% - 45px);
	top: 40%;
}

.loading {
	position: absolute;
	left: calc(50% - 35px);
	top: 50%;
	z-index: 999;
	width: 55px;
	height: 55px;
	border-radius: 50%;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	border: 0 solid transparent;
}

.loading .effect-1,
.loading .effect-2 {
	position: absolute;
	width: 100%;
	height: 100%;
	border: 3px solid transparent;
	border-left: 3px solid var(--primary);
	border-radius: 50%;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

.loading .effect-1 {
	animation: rotate 1s ease infinite;
}

.loading .effect-2 {
	animation: rotateOpacity 1s ease infinite 0.1s;
}

.loading .effect-3 {
	position: absolute;
	width: 100%;
	height: 100%;
	border: 3px solid transparent;
	border-left: 3px solid var(--primary);
	-webkit-animation: rotateOpacity 1s ease infinite 0.2s;
	animation: rotateOpacity 1s ease infinite 0.2s;
	border-radius: 50%;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

.loading .effects {
	transition: all 0.3s ease;
}

@keyframes rotate {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(1turn);
		transform: rotate(1turn);
	}
}

@keyframes rotateOpacity {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
		opacity: 0.1;
	}

	100% {
		-webkit-transform: rotate(1turn);
		transform: rotate(1turn);
		opacity: 1;
	}
}

/* TABS */

.tabs {
	-webkit-overflow-scrolling: touch;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-box-align: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	font-size: 1rem;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	overflow: hidden;
	overflow-x: auto;
	white-space: nowrap;
}

.tabs ul {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.tabs li {
	cursor: pointer;
	display: block;
}

.tabs li:hover {
	color: var(--primary);
}

.tabs li.active .link {
	border-bottom-color: var(--primary);
	border-bottom: 4px solid var(--primary);
	color: var(--primary);
}

.tabs .link {
	font-weight: 700;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom: 4px solid #dbdbdb;
	color: #4a4a4a;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	margin-bottom: -1px;
	vertical-align: top;
}

.tabs .disabled {
	color: #eee;
	cursor: not-allowed;
}

.tabs-sm .link {
	padding: 0.5rem 1rem;
	font-size: 1rem;
}

/* TABS */

/* SEARCH PRODUCT */

.search-not-found {
	display: flex;
	justify-content: center;
	font-size: 20px;
	padding: 2px;
}

.aa-input-container {
	display: inline-block;
	position: relative;
}

.dropdown-search-menu {
	position: absolute;
	display: grid;
	top: 77%;
	width: 100%;
	z-index: 1;
	right: auto;
}

.dropdown-menu-list {
	overflow: auto !important;
	background-color: #fff;
	border: 2px solid rgba(228, 228, 228, 0.6);
	border-top-width: 1px;
	width: 100%;
	margin-top: 10px;
	box-shadow: 4px 4px 0 rgba(241, 241, 241, 0.35);
	font-size: 15px;
	border-radius: 4px;
	box-sizing: border-box;
	max-height: 400px;
	overflow-y: scroll;
	margin-bottom: 5px;
}

.dropdown-menu-result-link {
	padding: 5px;
	display: flex;
	flex-direction: column;
}

.dropdown-menu-result-inner {
	display: flex;
	flex-direction: row;
}

.search-barcode {
	position: absolute;
	top: 2px;
	right: 7px;
	cursor: pointer;
	font-size: 25px;
}

.search-barcode:hover {
	color: var(--primary);
}

.input-plus-icon {
	position: absolute;
	top: 7px;
	right: 35px;
	cursor: pointer;
}

.text-sm {
	font-size: 0.8rem;
}

.input-right-icon {
	position: absolute;
	top: 8px;
	right: 8px;
	cursor: pointer;
	font-size: 18px;
}

.input-right-icon:hover {
	color: var(--primary);
}

.w-250px {
	width: 250px;
}

.min-h500 {
	height: calc(100vh - 125px);
}

.grouping-table {
	overflow-y: auto;
	height: calc(100vh - 320px);
}

/* TABLE STYLE */
.table-first-column-clickable:hover {
	text-decoration: underline !important;
}

.table-tr-active {
	background-color: #f8f9fa;
}

.bg-on-hover:hover {
	background-color: rgba(34, 41, 47, 0.05);
	cursor: pointer;
}

.tr-middle td {
	vertical-align: middle;
}

.th-sortable {
	cursor: pointer;
	position: relative;
	padding: 0.35rem 1rem 0.35rem 0.35rem !important;
}

.table-sort {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	overflow: hidden;
	position: absolute;
	right: 2px;
	top: 0;
}

.table {
	overflow: hidden;
}

.h-table-price-tags {
	min-height: 25px;
	max-height: 200px;
	overflow: auto;
}

.custom-truncate {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 0;
}

.inventory-table {
	min-height: 25px;
	max-height: 400px;
}

.auto-width-input {
	text-align: center;
	padding: 0px 5px;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 5px;
	outline: none;
	max-width: 150px;
	min-width: 85px;
	width: 85px;
	height: 38px;
}

.upload-from-excel {
	cursor: pointer;
	font-weight: inherit;
	font-size: inherit;
	color: inherit;
	text-overflow: initial;
	margin-bottom: 0;
	padding: 0;
	white-space: nowrap;
}

.footer {
	position: fixed;
	bottom: 0;
	width: 100%;
	padding: 5px 10px;
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
	background-color: #fff;
	display: flex;
	align-items: center;
	gap: 10px;
	z-index: 2;
}

.abous-us {
	bottom: 10px;
	color: #fff;
	left: 10px;
	z-index: 10;
}

.form-group {
	width: 100%;
}

.fuel-in-group {
	display: flex;
	align-items: center;
	height: 70px;
}

.react-select__control--is-focused {
	border-color: var(--primary) !important;
	box-shadow: 0 0 0 1px var(--primary) !important;
	transition: 0.1s;
}

.custom-modal-width {
	width: 90vw;
	max-width: 90vw;
	/* min-height: 250px; */
	max-height: 85vh;
	/* overflow: auto; */
}

.modal-body .table-responsive {
	max-height: 70vh;
	overflow: auto;
}

.modal-body .table-responsive table {
	overflow: auto;
}

.modal-body .table-responsive table thead {
	position: sticky;
	top: -1px;
	background-color: var(--theme-color);
}

/* .modal-body .btn-primary {
	white-space: nowrap;
} */

.d-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.custom-checkbox {
	display: block;
	position: relative;
	cursor: pointer;
	font-size: 16px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	height: 16px;
	width: 16px;
	margin-bottom: 0;
}

/* Hide the browser's default checkbox */
.custom-checkbox input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 16px;
	width: 16px;
	background-color: #ccc;
}

/* On mouse-over, add a grey background color */
.custom-checkbox:hover input~.checkmark {
	background-color: #a8a8a8;
}

/* When the checkbox is checked, add a blue background */
.custom-checkbox input:checked~.checkmark {
	background-color: var(--primary);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.custom-checkbox input:checked~.checkmark:after {
	display: block;
}

/* Style the checkmark/indicator */
.custom-checkbox .checkmark:after {
	left: 6px;
	top: 2px;
	width: 4px;
	height: 11px;
	border: solid white;
	border-width: 0 2px 2.5px 0;
	transform: rotate(45deg);
}

.app-version {
	position: absolute;
	bottom: 5px;
	left: 0;
}

.balance-card {
	position: relative;
	width: 40%;
	margin: auto;
	margin-bottom: 10px;
	border: 1px solid #212121;
	padding: 10px 30px;
	border-radius: 5px;
}

.balance-card .col-md-8 {
	border-right: 1px dashed #212121;
}

/* Tooltip */

.tooltip-container {
	position: relative;
	display: inline-block;
}

.tooltip-box {
	position: absolute;
	background-color: #333;
	color: #fff;
	padding: 8px;
	border-radius: 4px;
	white-space: nowrap;
	z-index: 10;
	opacity: 0;
	transition: opacity 0.5s;
}

.tooltip-container:hover .tooltip-box {
	opacity: 1;
}

.tooltip-box.top {
	bottom: 100%;
	left: 50%;
	transform: translateX(-50%);
	margin-bottom: 8px;
}

.tooltip-box.right {
	top: 50%;
	left: 100%;
	transform: translateY(-50%);
	margin-left: 8px;
}

.tooltip-box.bottom {
	top: 100%;
	left: 50%;
	transform: translateX(-50%);
	margin-top: 8px;
}

.tooltip-box.left {
	top: 50%;
	right: 100%;
	transform: translateY(-50%);
	margin-right: 8px;
}

/* Tooltip end */

.ios-switch {
	appearance: none;
	outline: none;
	border: 0;
	border-radius: 1em;
	position: relative;
	width: 5em;
	height: 2em;
	overflow: hidden;
	cursor: pointer;
}

.ios-switch:after {
	content: "";
	position: absolute;
	width: 1.5em;
	height: 1.5em;
	top: 0.2em;
	left: 0.2em;
	background: white;
	border-radius: 50%;
	transition: all 0.1s;
	box-shadow: 1px 0 5px rgba(0, 0, 0, 0.2);
}

.ios-switch:checked:after {
	transform: translateX(3em);
	box-shadow: -1px 0 5px rgba(0, 0, 0, 0.2);
}

.light {
	background: #c7c7cc;
}

.light:disabled {
	opacity: 0.5;
}

.light:checked {
	background: var(--primary);
}

.tr-nowrap td {
	white-space: nowrap;
}

.table-input {
	border: 1px solid rgba(0, 0, 0, .2);
	border-radius: 5px;
	max-width: 150px;
	min-width: 85px;
	outline: none;
	padding: 0 5px;
	text-align: center;
	transition: .3s;
	width: 85px;
}

.test-header {
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: space-around;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	width: 100%;
	max-width: 1140px;
	background-color: #fff;
	height: 60px;
}

.text-content {
	padding-top: 70px;
	height: calc(100vh - 70px);
}