#page-topbar {
	position: fixed;
	top: 0;
	right: 0;
	left: 250px;
	z-index: 1002;
	background-color: #ffffff;
}

.navbar-header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: justify;
	-webkit-box-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin: 0 auto;
	height: 50px;
	padding: 0 calc(1.25rem / 2);
	box-shadow: 0 2px 0px rgb(15 34 58 / 12%);
}

.navbar-header .dropdown .show.header-item {
	background-color: #f8f9fa;
}

.navbar-header .navbar-brand-box {
	display: none;
}

body.vertical-collpsed #page-topbar {
	left: 70px;
}

@media (max-width: 991.98px) {
	body.vertical-collpsed #page-topbar {
		left: 0;
	}
}

body:not(.vertical-collpsed) .navbar-header .vertical-menu-btn {
	display: none;
}

body:not(.vertical-collpsed) .app-search {
	margin-left: 10px;
}

.navbar-brand-box {
	text-align: center;
	width: 250px;
	position: fixed;
	z-index: 1;
	background-color: #ffffff;
}

.logo {
	line-height: 50px;
}

.logo .logo-sm {
	display: none;
}

.logo-dark {
	display: block;
}

.logo-light {
	display: none;
}

/* Search */
.app-search {
	padding: calc(32px / 2) 0;
}

.app-search .form-control {
	border: none;
	height: 38px;
	padding-left: 40px;
	padding-right: 20px;
	background-color: transparent;
	-webkit-box-shadow: none;
	box-shadow: none;
	border-radius: 5px;
}

.app-search span {
	position: absolute;
	z-index: 10;
	font-size: 16px;
	line-height: 38px;
	left: 13px;
	top: 0;
	color: #74788d;
}

.megamenu-list li {
	position: relative;
	padding: 5px 0px;
}

.megamenu-list li a {
	color: #495057;
}

@media (max-width: 991.98px) {
	.navbar-brand-box {
		width: auto;
	}

	#page-topbar {
		left: 0;
	}

	#page-topbar .navbar-header .navbar-brand-box {
		display: inline-block;
		position: relative;
	}

	#page-topbar .navbar-header .vertical-menu-btn {
		display: inline-block;
	}

	.logo span.logo-lg {
		display: none;
	}

	.logo span.logo-sm {
		display: inline-block;
	}
}

.page-content {
	padding: 60px 10px 10px 10px;
}

.header-item {
	height: 50px;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
	color: #555b6d;
	border: 0;
	border-radius: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.header-item:hover {
	color: #555b6d;
}

.header-profile-user {
	height: 36px;
	width: 36px;
	background-color: #f6f6f6;
	padding: 3px;
}

.noti-icon i {
	font-size: 22px;
	color: #555b6d;
}

.noti-icon .badge {
	position: absolute;
	top: 12px;
	right: 5px;
}

.notification-item .d-flex {
	padding: 0.75rem 1rem;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.notification-item .d-flex:hover {
	background-color: #f8f9fa;
}

.dropdown-icon-item {
	display: block;
	border-radius: 3px;
	line-height: 34px;
	text-align: center;
	padding: 15px 0 9px;
	display: block;
	border: 1px solid transparent;
	color: #74788d;
}

.dropdown-icon-item img {
	height: 24px;
}

.dropdown-icon-item span {
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.dropdown-icon-item:hover {
	border-color: #f5f6f8;
}

.fullscreen-enable [data-bs-toggle="fullscreen"] .uil-minus-path::before {
	content: '\eb8d';
}

.profile-dropdown {
	position: relative;
}

.profile-dropdown-toggle {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 35px;
	width: 35px;
	margin-left: auto;
	background: #d7d7d7;
	border-radius: 50%;
}

.profile-dropdown-toggle i {
	color: #fff;
}

.profile-dropdown-toggle::after {
	display: none;
}

.profile-dropdown-toggle.danger {
	border: 1px solid #f46a6a;
}

.profile-dropdown-toggle.success {
	border: 1px solid #34c38f;
}

.profile-dropdown-menu {
	position: absolute;
	background-color: #fff;
	box-shadow: 0 5px 25px 0 rgba(0, 0, 0, .1);
	width: 230px;
	right: 0;
	top: 35px;
	padding: 10px 0;
	border-radius: 5px;
	cursor: default;
}

.profile-dropdown-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.35rem 1.5rem;
}

.profile-dropdown-item.second:hover {
	cursor: pointer;
	background-color: #f8f9fa;
	color: #2c3034;
}

body[data-topbar="dark"] #page-topbar {
	background-color: #2f374e;
}

body[data-topbar="dark"] .navbar-header .dropdown .show.header-item {
	background-color: rgba(255, 255, 255, 0.05);
}

body[data-topbar="dark"] .navbar-header .waves-effect .waves-ripple {
	background: rgba(255, 255, 255, 0.4);
}

body[data-topbar="dark"] .header-item {
	color: #e9ecef;
}

body[data-topbar="dark"] .header-item:hover {
	color: #e9ecef;
}

body[data-topbar="dark"] .header-profile-user {
	background-color: rgba(255, 255, 255, 0.25);
}

body[data-topbar="dark"] .noti-icon i {
	color: #e9ecef;
}

body[data-topbar="dark"] .logo-dark {
	display: none;
}

body[data-topbar="dark"] .logo-light {
	display: block;
}

body[data-topbar="dark"] .app-search .form-control {
	background-color: #37405b;
	color: #fff;
}

body[data-topbar="dark"] .app-search span,
body[data-topbar="dark"] .app-search input.form-control::-webkit-input-placeholder {
	color: rgba(255, 255, 255, 0.5);
}

body[data-sidebar="dark"] .navbar-brand-box {
	background: #1c2742;
}

body[data-sidebar="dark"] .navbar-brand-box .logo-dark {
	display: none;
}

body[data-sidebar="dark"] .navbar-brand-box .logo-light {
	display: block;
}

@media (max-width: 600px) {
	.navbar-header .dropdown {
		position: static;
	}

	.navbar-header .dropdown .dropdown-menu {
		left: 10px !important;
		right: 10px !important;
	}
}

body[data-layout="horizontal"] .page-content {
	margin-top: 70px;
	padding: calc(80px + 1.25rem) calc(1.25rem / 2) 60px calc(1.25rem / 2);
}

@media (max-width: 991.98px) {
	body[data-layout="horizontal"] .page-content {
		margin-top: 0px;
	}
}

@media (max-width: 374.99px) {
	.navbar-brand-box {
		padding: 0 1rem;
		text-align: center;
	}

	.language-switch {
		display: none !important;
	}
}