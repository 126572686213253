.shiftReport__container {
	padding: 8px;
	background-color: #F5F6F8;
	font-size: 14px;
	height: 100%;
	padding-top: 55px;
	padding-bottom: 60px;
	overflow: auto;
}

.general__container {
	/* height: 25% !important; */
	padding: 0 15px 5px;
	/* overflow-y: auto; */
}

/* .sale__container {
	height: 23% !important;
	overflow-y: auto;
}

.storage__container {
	height: 15% !important;
	overflow: auto;
}

.trk__container {
	height: 26% !important;
	overflow: auto;
} */

.general__field {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 0.25rem;
}

.general__field span {
	font-size: 12px;
}

.general__field:last-child {
	margin-bottom: 0;
}

.special__paragraphs {
	display: flex;
	justify-content: flex-end;
}

.special__paragraphs span {
	font-size: 12px;
}

.shiftReport__container input {
	text-align: right;
}

.accuray-modal-body {
	vertical-align: middle !important;
	font-size: 16px;
}

.inkassa-modal-body {
	vertical-align: middle !important;
	font-size: 16px;
	max-height: 400px !important;
	overflow-y: auto !important;
}

.invoice-modal-body {
	vertical-align: middle !important;
	font-size: 14px !important;

}

.report__input {
	width: 200px;
	margin-left: 1rem;
}

.sold__table--th {
	text-align: center;
	vertical-align: middle;
}

.sold__table--th th {
	padding: 1 4px !important;
	line-height: 1;
	font-weight: 500;
}

.sold__table--td {
	text-align: right;
	vertical-align: middle;
}

.sold__table--height {
	height: 100% !important;
	overflow-y: auto !important;
	border-bottom: 1px solid lightgray;
}

.report__btn--modal {
	height: 35px;
	margin: auto 0 auto 30px;
}

.sticky__position--table {
	overflow-y: auto;
	height: 80px;
	position: relative;
}

.sticky__position--table thead th {
	position: sticky;
	top: -2px;
	z-index: 2;
	border: none;
	background-color: #F0F0F0;
	font-size: 12px !important;
	padding: 2px 0.25rem !important;
}

.report_table thead th {
	position: sticky;
	top: -2px;
	z-index: 2;
	border: none;
	background-color: #fff;
	border: 1px solid #000;
}

.shiftReport__container .table th,
.shiftReport__container .table td {
	text-align: end;
}

.print-input {
	text-align: end;
	height: 35px;
}

/* Print table */
.table.print-table {
	font-size: 9px;
}

.table.print-table th {
	text-align: center !important;
	border: none !important;
	position: -webkit-sticky;
	position: sticky;
	font-size: inherit;
	word-break: break-all;
}

.table.print-table th::after {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	border: 1px solid #000;
	border-left: none;
	z-index: 1;
}

.table.print-table th.after-100::after {
	width: 100%;
}

.table.print-table th.after-101::after {
	width: calc(100% + 1px);
}

.table.print-table th.after-102::after {
	width: calc(100% + 1px);
	margin-left: -1px;
}

.table.print-table th.border-none::after {
	border: none !important;
}

.table.print-table th:first-child::after {
	border-left: 1px solid #000;
}

.table.print-table tbody tr:first-child td {
	border-top: none;
}

.sticky__position--table tbody tr td {
	border: 1px solid #1b1b1b;
	font-size: 12px;
	padding: 0 0.25rem;
}

.print-table> :not(:last-child)> :last-child>* {
	border: none !important;
}

.print-table> :not(:last-child)> :last-child>*::after {
	border-top: none;
	border-left: none;
}

.print-table> :not(:last-child)> :last-child>*:first-child:after {
	border-left: none;
}

.print-table tr td {
	padding: 0;
	border: 1px solid #1b1b1b;
	font-size: inherit;
	white-space: nowrap;
}

.print-table tr td.text-end {
	padding-right: 5px;
}

/* Print table end */

/*  Balance Table */

.table.balance-table th {
	border: 1px solid #000;
	text-align: center;
}

.table.balance-table td {
	border: 1px solid #000;
	text-align: right;
}

/* Balance Table */

.tr-active td:first-child {
	background-color: var(--primary);
	color: #fff;
	transition: 0.2s;
}

.page-topbar {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background-color: #fff;
	box-shadow: 0 2px 4px rgb(15 34 58 / 12%);
	z-index: 1000;
}

.navbar-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0 auto;
	height: 40px;
	padding: 0 20px;
}

.settings-modal-body {
	vertical-align: middle !important;
	font-size: 16px;
	height: 70vh;
	overflow-y: auto;
}

.smena-modal-body {
	vertical-align: middle !important;
	font-size: 16px;
}

.inventory-modal-body {
	vertical-align: middle !important;
	font-size: 16px;
}

.small__input {
	width: 100px;
	padding: 1px 8px;
	border: 1px solid lightgray;
	/* background-color: transparent; */
	border-radius: 4px;
	height: 20px;
	margin-left: 1rem;
	font-size: 12px;
}

.small_btn {
	width: 100px;
	padding: 1px 8px;
	border: 1px solid lightgray;
	/* background-color: transparent; */
	border-radius: 4px;
	height: 20px;
	font-size: 12px;
}

.trk_input {
	width: 200px;
	height: 30px;
	padding: 1px 8px;
	border: 1px solid lightgray;
	/* background-color: transparent; */
	border-radius: 4px;
	font-size: 12px;
	text-align: start !important;
}

.trk_input:focus-visible {
	outline: none;
}

.long__input {
	width: 200px;
	padding: 1px 8px;
	border: 1px solid lightgray;
	background-color: transparent;
	border-radius: 4px;
	height: 26px;
	margin-left: 1rem;
}

.long__input:focus, .small__input:focus {
	outline: none;
}

.settings__form--position {
	width: 350px !important;
	margin-right: 50px;
}

.expandIcon {
	cursor: pointer;
	margin: 0 32px;
	font-size: 22px;
}

.cursor__span span {
	cursor: pointer;
}

/* =============== */

/* containers */
.prixod__container {
	height: 100vh;
	background-color: #F5F6F8;
	padding: 65px 16px 0 16px;
	font-size: 14px;
	vertical-align: middle !important;
}

.nakladnoy__container {
	padding: 0 1rem;
	margin-right: 8px;
	height: calc(100vh - 150px);
	overflow: auto;
}

.nakladnoy__form {
	display: flex;
	flex-direction: column;
}

.info__container {
	padding: 0 1rem;
	margin-bottom: 8px;
	height: calc(70% - 8px) !important;
}

.prixod__right--form {
	height: calc(100vh - 154px) !important;
}

.doverennost__container {
	padding: 0 1rem;
	height: 30% !important;
}

.forms__container {
	width: 100%;
}

/* containers */

/* inputs */
.input__div {
	display: flex;
	margin-bottom: 8px;
	align-items: center;
}

.input__div label {
	width: 110px;
	vertical-align: middle;
	text-align: right !important;
	margin-right: 12px;
	white-space: nowrap;
}

.input__kostil span {
	width: 110px;
	vertical-align: middle;
	text-align: right !important;
	margin-right: 12px;
	white-space: nowrap;
}

.input__kostil label {
	width: 90px;
}

.time__input {
	width: 70px;
	padding: 1px 8px !important;
	text-align: center !important;
	border: 1px solid lightgray;
	/* background-color: transparent; */
	border-radius: 4px;
	height: 20px !important;
	margin: 0 1rem;
	font-size: 12px;
}

.select__input {
	width: 250px !important;
	display: flex;
	flex-direction: column !important;
	margin-left: 16px;
	height: 30px !important;
}

.textarea-input {
	margin-left: 1rem;
	border: 1px solid lightgray;
	padding: 1px 8px;
	resize: none;
}

/* inputs */

/* buttons */

.buttons__container button {
	width: 70px;
	height: 35px;
	padding-top: 4px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: 1px solid #212121;
	background-color: white;
	border-radius: 4px;
	transition: 0.3s;
	font-weight: 500;
}

.buttons__container.second {
	display: flex;
	align-items: center;
	gap: 10px;
}

.buttons__container.second button {
	height: fit-content;
}

.buttons__container.second button i {
	font-size: 18px;
}

.buttons__container button:disabled {
	pointer-events: none;
	border: 1px solid #ccc;
}

.buttons__container button:hover {
	background-color: var(--primary);
	color: #fff;
	border: 1px solid transparent;
}

.buttons__container button i {
	font-size: 24px;
}

.unknown__btn {
	height: 30px;
	border: 1px solid lightgray;
	background-color: transparent;
	width: 40px;
	margin-left: 16px;
	border-radius: 4px;
}

/* buttons */

/* icons */
.btn__icons {
	font-size: 40px !important;
	color: #007BFF;
}

.btn__danger {
	font-size: 40px;
	color: #DC3545;
}

/* icons */


.td-input {
	padding: 0 !important;
	height: 100%;
}

.td-input input {
	border: none !important;
	padding: 0 0.25rem;
	font-size: 14px;
	height: 100%;
}

.td-input input:disabled {
	background-color: #cdcdcd;
	cursor: default;
	border-radius: 0;
}


.td-input.error,
.small__input.error,
.td-input.error .form-control {
	background-color: #ec6060;
	color: #fff;
}

.table td {
	vertical-align: middle !important;
}

.tr-bold td {
	font-weight: bold;
}

.print_card {
	border: 1px dashed #212121;
	border-radius: 12px;
	padding: 10px 15px;
	text-align: center;
	font-size: 10px;
}

hr:not([size]) {
	margin: 5px 0;
	color: #212121;
}

.border-none {
	border: none !important;
}

.print_card .table thead th {
	padding: 0 0.25rem !important;
	font-size: 10px;
	border-bottom: none;
	font-weight: normal;
}

.print_card td {
	padding: 0 0.25rem !important;
	font-size: 10px;
}

.devider {
	height: 1px;
	background-image: linear-gradient(to right, black 50%, rgba(255, 255, 255, 0.5) 10%);
	background-position: bottom;
	background-size: 13px 1px;
	background-repeat: repeat-x;
	margin: 10px 0;
}

tbody {
	border: none !important;
}

.version {
	position: absolute;
	right: 5px;
	text-align: center;
	line-height: 16px;
	font-size: 14px;
	display: flex;
	align-items: center;
	gap: 15px;
}

.version-devider {
	height: 32px;
	width: 1px;
	background: #a9a9a9;
}

.table.table-bordered th {
	border: 1px solid #212121;
}

.table-btn button {
	border-radius: 0;
}

.td-input .react-select__control {
	border: none;
	border-radius: 0;
}

@media print {
	@page {
		size: auto;
		margin: 0mm;
		padding: 0mm;
		float: none;
	}

	thead {
		display: table-row-group;
	}
}

.tr-border-none td {
	border-bottom: none !important;
	border-top: none !important;
}

.tr-center td {
	text-align: center;
}

.tr-start th,
.tr-start td {
	text-align: start;
}